import React, { useEffect, useState } from "react"
import FetchClient from "../utils/FetchClient"
import Layout from "../components/layout"
import HowtoBlock from "../components/howtoBlock"
import BenefitBlock from "../components/benefitBlock"
import SignupBlock from "../components/signupBlock"

import Carousel, { autoplayPlugin, slidesToShowPlugin } from "@brainhubeu/react-carousel"
import "@brainhubeu/react-carousel/lib/style.css"

import styles from "./home.module.css"

// move in its own file
function offset(el) {
    var rect = el.getBoundingClientRect(),
    scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
    scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return { top: rect.top + scrollTop, left: rect.left + scrollLeft }
}

export default function Home() {
	const [carouselItems, setCarouselItems] = useState(null);

	useEffect(() => {
		FetchClient({
			method: 'GET', 
			url: `${process.env.GATSBY_API_URL}/pub/current_preview`
		})
		.then(res => {
			setCarouselItems(res);
		})
		.catch(err => { console.log(err) })

	}, [])

	const _scrollToSignup = () => {
		let formPos = offset(document.getElementById('footer-signup-form'));
		window.scrollTo({ top: formPos.top, behavior: 'smooth' });
	}

	return (
		<Layout>
			<div className={[ styles.hero].join(' ')}>
				<div className="wrapper">
					<h1>Meet your <span>healthy</span> vegetarian meal plan</h1>
					<p className={["lead", styles.heroLead].join(' ')}>Delicious healthy meals balanced to get the most out of your vegetarian diet. From breakfast to dinner, all day, every day.</p>
					<div className={styles.actions}>
						<a href={`${process.env.GATSBY_APP_URL}/signup`} className="btn btn-xl">Sign up for free</a>
						<p className={["caption", styles.heroSubline].join(' ')}>Get 1 plan free forever. <span>No credit card required.</span></p>
					</div>
				</div>
			</div>
			<div className={styles.howto}>
				<div className="wrapper">
					<h2>How does Vegly work?</h2>
					<div className={[ styles.steps, 'grid' ].join(' ')}>
						<HowtoBlock step="1" title="Nutritionally balanced" desc="Use our weekly meal plans as they are or customise them to suit your needs" classes={styles.howtoPlan}/>
						<HowtoBlock step="2" title="Grocery list" desc="Easily create your grocery list to use where and when you need" classes={styles.howtoShop}/>
						<HowtoBlock step="3" title="Easy cooking" desc="You are all set now. Cook healthy yet tasty vegetarian food you'll love" classes={styles.howtoCook}/>
					</div>
				</div>
			</div>
			<div className={styles.benefits}>
				<div className="wrapper">
					<h2>Why should I use Vegly?</h2>
					<p className="lead">Having a vegetarian diet can be difficult. So we make it easy...and delicious</p>
					<div className={[ styles.benefitsList, 'grid' ].join(' ')}>
						<BenefitBlock 
							title="Stop thinking what to eat"
							desc="Each Vegly plan includes breakfast, lunch, dinner and snacks for the entire week."
						/>
						<BenefitBlock 
							title="Follow a balanced diet"
							desc="Carefully balanced to make sure you get the right nutrition."
						/>
						<BenefitBlock 
							title="Automatic grocery lists"
							desc="No more paper lists or missing ingredients. Vegly creates your grocery list for you."
						/>
						<BenefitBlock 
							title="Reduce food waste"
							desc="Our plans are specifically designed to minimise food waste even for singles, so you can save money with less waste."
						/>
						<BenefitBlock 
							title="Tasty meals, even if you are busy"
							desc="All our recipes come with a step by step method so they are quick and easy to cook."
						/>
						<BenefitBlock 
							title="Cooking just got personal"
							desc="Customise your meal plan to suit you, and keep track of what you eat as you go."
						/>
					</div>
				</div>
			</div>
			<div className={styles.reel}>
				<h2>Discover this week's plan</h2>
				<p className="lead">Each week our team creates balanced and healthy plans with delicious recipes.</p>
				{carouselItems && 
					<div className={styles.carouselWrapper}>
						<Carousel
							infinite
							centered
							autoPlay={2000}
							/*
							plugins={[
								{
									resolve: autoplayPlugin,
									options: { interval: 2000 },
								}
							]}
							*/
							itemWidth={240}
							offset={8}
						>
							{carouselItems.map((item, index) => <img key={index} src={`${process.env.GATSBY_API_HOST}/${item.thumb}`} heigth={240} width={240}/>)}
						</Carousel>
					</div>
				}
				<a className="btn btn-xl" onClick={_scrollToSignup}>Sign up for free</a>
			</div>
			<SignupBlock/>
		</Layout>
  	)
}
