import React from "react"

export default function HowtoBlock({ step, title, desc, classes }) {
    return (
        <div className={classes}>
            <i></i>
            <span>{step}</span>
            <h6>{title}</h6>
            <p>{desc}</p>
        </div>
    )
}