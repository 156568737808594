var today = new Date();
    today.setDate(today.getDate() + 28);

// TODO add security on cookie for production

const Auth = {
    isAuthenticated: () => {
        let authCookie = document.cookie.replace(/(?:(?:^|.*;\s*)_vy_auth\s*=\s*([^;]*).*$)|^.*$/, "$1"); 
        return authCookie || false;
    },

    signin: (token) => {
        // TODO add secure flag and domain protection for production
        let cookie = `_vy_auth=${token}; path=/; domain=${process.env.GATSBY_APP_COOKIE_DOMAIN}; expires=${today.toUTCString()};`;
        document.cookie = cookie;
    },
    
    signout: () => {
        let cookie = `_vy_auth=; path=/; domain=${process.env.GATSBY_APP_COOKIE_DOMAIN}; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        document.cookie = cookie;
    }
};

export default Auth;